import * as Avatar from "@radix-ui/react-avatar";

import "~/modules/Dashboard/TopList/styles/AvatarComponent.scss";

const AvatarComponent = ({ profilePicture }: { profilePicture: string }) => {
  return (
    <Avatar.Root>
      <Avatar.Image
        src={profilePicture}
        alt="Profile Picture"
        className="toplist-avatar"
      />
      <Avatar.Fallback delayMs={1000}>
        <img
          src="/logoer/profileImageBackup.png"
          alt="Standard Image"
          className="toplist-avatar"
        />
      </Avatar.Fallback>
    </Avatar.Root>
  );
};

export default AvatarComponent;
