import * as Avatar from "@radix-ui/react-avatar";
import { Flex } from "@radix-ui/themes";

import "../styles/ActivityBadges.scss";

type ActivityBadgesProps = {
  badgeImages: string[];
};

export const ActivityBadges = ({ badgeImages }: ActivityBadgesProps) => {
  return (
    <Flex
      className="activity-badges"
      width={{ initial: "4em", sm: "6em", lg: "8em" }}
      justify={"center"}
      wrap={"wrap"}
    >
      {badgeImages.map((img, imgIndex) => (
        <Avatar.Root key={imgIndex} className="avatar-root">
          <Avatar.Image src={img} alt={img} className="badge-image" />
        </Avatar.Root>
      ))}
    </Flex>
  );
};
