import * as Avatar from "@radix-ui/react-avatar";
import { Flex, Text } from "@radix-ui/themes";
import { Box } from "@radix-ui/themes";

import { Badge } from "~/models/Badge.model";

import "~/modules/Dashboard/TopList/styles/BadgeComponents.scss";

type ExpandedBadgesProps = {
  badge: Badge;
};

export const ExpandedBadge = ({ badge }: ExpandedBadgesProps) => {
  return (
    <Flex className={"badge-component"}>
      <Flex align={"center"} gap="1">
        <Avatar.Root className={`badge-root expanded`}>
          <Avatar.Image
            src={badge.image}
            alt="Badge Image"
            className="badge-image"
          />
        </Avatar.Root>
        <Box display={{ initial: "none", xs: "block" }}>
          <Text className="badge-title" wrap={"nowrap"}>
            {badge.title}
          </Text>
        </Box>
      </Flex>
    </Flex>
  );
};