import { Flex } from "@radix-ui/themes";

import { Athlete } from "~/models/Athlete.model";
import { AthleteActivity } from "~/models/AthleteActivity.model";
import { Badge } from "~/models/Badge.model";
import { ExpandedBadgesTitle } from "~/modules/Dashboard/TopList/components/Badges/ExpandedBadgesTitle";
import { BadgesOverview } from "~/modules/Dashboard/TopList/components/BadgesOverview";
import { EmptyExpandedTitle } from "~/modules/Dashboard/TopList/components/ExpandedAthlete/EmptyExpandedTitle";
import { ExpandedMobileContribution } from "~/modules/Dashboard/TopList/components/ExpandedAthlete/ExpandedMobilContribution";

import { ExpandedAthleteEntries } from "./ExpandedAthleteEntries";
import { ExpandedAthleteTitle } from "./ExpandedAthleteTitle";

import "~/modules/Dashboard/TopList/styles/ExpandedAthlete.scss";

type ExpandedAthleteProps = {
  activities: AthleteActivity[];
  badges: Badge[];
  individualContribution?: Athlete["individualContribution"];
};

export const ExpandedAthlete = ({
  activities,
  badges,
  individualContribution,
}: ExpandedAthleteProps) => {
  const isEmpty = activities.length == 0 && badges.length == 0;

  return (
    <Flex className="expanded-athlete" direction="column" px="4">
      {isEmpty && <EmptyExpandedTitle />}

      {individualContribution && (
        <Flex gap="1" direction="column" pb={"3"}>
          <ExpandedMobileContribution totalSum={individualContribution} />
        </Flex>
      )}

      {badges.length > 0 && (
        <Flex gap="1" direction="column" pb={"6"}>
          <ExpandedBadgesTitle />
          <BadgesOverview badges={badges} />
        </Flex>
      )}

      {activities.length > 0 && (
        <Flex gap="1" direction="column">
          <ExpandedAthleteTitle />
          <ExpandedAthleteEntries activities={activities} />
        </Flex>
      )}
    </Flex>
  );
};
