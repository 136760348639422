import { Text } from "@radix-ui/themes";

type ExpandedAthleteToggleLinkProps = {
  toggleExpand: (e: React.MouseEvent<HTMLElement>) => void;
  title: string;
};

export const ExpandedAthleteToggleLink = ({
  title,
  toggleExpand,
}: ExpandedAthleteToggleLinkProps) => {
  return (
    <Text align="right" size="1" weight="bold">
      <a href="#" onClick={toggleExpand}>
        {title}
      </a>
    </Text>
  );
};
