import { FC } from "react";

import { Flex, Text } from "@radix-ui/themes";

import { LatestActivity } from "~/modules/Dashboard/LatestActivities/LatestActivity.model";

import { ActivityBadges } from "./components/ActivityBadges";
import { ActivityInfo } from "./components/ActivityInfo";
import { ActivityTime } from "./components/ActivityTime";

interface LatestActivitiesProps {
  data: LatestActivity[];
}

export const LatestActivities: FC<LatestActivitiesProps> = ({ data }) => {
  return (
    <>
      {data.length === 0 ? (
        <Text>Ingen aktiviteter funnet.</Text>
      ) : (
        data.map((activity, key) => (
          <Flex key={key} align="center" gap="1" pb={"4"} width={"auto"}>
            <ActivityBadges badgeImages={activity.badgeImages} />
            <Flex
              direction="row"
              justify="between"
              align="baseline"
              width="100%"
              mr="2em"
              gap="3"
            >
              <ActivityInfo
                athleteName={activity.athleteName}
                activityDistanceKM={activity.activityDistanceKM}
                activityBadgeNames={activity.activityBadgeNames}
                sportTypeInfo={activity.sportTypeInfo}
              />
              <ActivityTime
                endTimeMobile={activity.activityEndTimeMobile}
                endTimeDesktop={activity.activityEndTimeDesktop}
              />
            </Flex>
          </Flex>
        ))
      )}
    </>
  );
};
