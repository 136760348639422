import React from "react";

import { Text } from "@radix-ui/themes";

import { LatestActivity } from "~/modules/Dashboard/LatestActivities/LatestActivity.model";

type ActivityBadgeNamesProps = {
  badgeList: LatestActivity["activityBadgeNames"];
};
export const ActivityBadgeNames = ({ badgeList }: ActivityBadgeNamesProps) => {
  const introText = "og oppnådde ";

  return (
    <>
      <Text size="2">
        <Text>{introText}</Text>
        {badgeList.map((badge, index) => {
          if (index === 0) {
            return (
              <Text key={index} weight="bold">
                {badge}
              </Text>
            );
          } else if (index === badgeList.length - 1) {
            return (
              <React.Fragment key={index}>
                <Text> og </Text>
                <Text weight="bold">{badge}</Text>
              </React.Fragment>
            );
          } else {
            return (
              <React.Fragment key={index}>
                <Text>, </Text>
                <Text weight="bold">{badge}</Text>
              </React.Fragment>
            );
          }
        })}
      </Text>
    </>
  );
};
