import { ContentSection } from "~/Layout/Sections/ContentSection";

import { Badges } from "./Badges/Badges";
import { ComputasTVAksjonen } from "./ComputasTVAksjonen/ComputasTVAksjonen";
import { Footer } from "./Footer/Footer";
import { Header } from "./Header/Header";
import { Podium } from "./Podium/Podium";
import { ProgressBar } from "./ProgressBar/ProgressBar";
import { TabTopListActivities } from "./TabTopListActivities/TabTopListActivities";

export const Dashboard = () => {
  return (
    <>
      <Header />
      <ContentSection>
        <ProgressBar />
        <Podium />
        <TabTopListActivities />
        <Badges />
        <ComputasTVAksjonen />
      </ContentSection>
      <Footer />
    </>
  );
};
