import { Box, Flex, Text } from "@radix-ui/themes";

import { AthleteActivity } from "~/models/AthleteActivity.model";

type ExpandedAthleteRowProps = {
  activity: AthleteActivity;
};

export const ExpandedAthleteRow = ({ activity }: ExpandedAthleteRowProps) => {
  return (
    <Flex gap="1" width="100%" wrap="wrap" align="center" justify="between">
      {/* Date */}
      <Box
        display={{ sm: "none" }}
        style={{
          flexBasis: "15%",
          maxWidth: "15%",
          overflow: "hidden",
          whiteSpace: "nowrap",
        }}
      >
        <Text size="2" weight="medium">
          {activity.activityDateMobile}
        </Text>
      </Box>

      {/* Date */}
      <Box
        display={{ initial: "none", sm: "block" }}
        style={{
          flexBasis: "15%",
          maxWidth: "15%",
          overflow: "hidden",
          whiteSpace: "nowrap",
        }}
      >
        <Text size="2" weight="medium">
          {activity.activityDate}
        </Text>
      </Box>
      {/* Activity Name */}
      <Box
        style={{
          flexBasis: "45%",
          maxWidth: "45%",
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
        }}
      >
        <Text size="2" weight="medium">
          {activity.activityName}
        </Text>
      </Box>

      {/* Distance */}
      <Box
        display={{ initial: "none", sm: "block" }}
        style={{
          flexBasis: "25%",
          maxWidth: "25%",
          overflow: "hidden",
          whiteSpace: "nowrap",
          textAlign: "right",
        }}
      >
        <Text size="2">{activity.distanceKM} km</Text>
      </Box>
      {/* Distance */}
      <Box
        display={{ initial: "block", sm: "none" }}
        style={{
          flexBasis: "25%",
          maxWidth: "25%",
          overflow: "hidden",
          whiteSpace: "nowrap",
          textAlign: "right",
        }}
      >
        <Text size="2">{activity.distanceKM} km</Text>
      </Box>
      {/* Elevation Gain - visible for larger screens */}
      <Box
        display={{ initial: "none", sm: "block" }}
        style={{
          flexBasis: "10%",
          maxWidth: "10%",
          overflow: "hidden",
          whiteSpace: "nowrap",
          textAlign: "right",
        }}
      >
        <Text size="2">{activity.totalElevationGain} m</Text>
      </Box>
    </Flex>
  );
};
