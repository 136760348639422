import { Box, Text } from "@radix-ui/themes";

import { LatestActivity } from "~/modules/Dashboard/LatestActivities/LatestActivity.model";

interface ActivityTimeProps {
  endTimeMobile: LatestActivity["activityEndTimeMobile"];
  endTimeDesktop: LatestActivity["activityEndTimeDesktop"];
}

export const ActivityTime = (endTime: ActivityTimeProps) => {
  const { endTimeMobile, endTimeDesktop } = endTime;
  return (
    <Text wrap={"nowrap"} size={"1"}>
      <Box display={{ initial: "block", xs: "none" }}>{endTimeMobile}</Box>
      <Box display={{ initial: "none", xs: "block" }}>{endTimeDesktop}</Box>
    </Text>
  );
};
