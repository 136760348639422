import { FC } from "react";

import { Table, Text } from "@radix-ui/themes";

export const MobileTopListHeader: FC = () => {
  return (
    <Table.Header>
      <Table.Row>
        <Table.ColumnHeaderCell align="left">
          <Text size="1">Plass</Text>
        </Table.ColumnHeaderCell>
        <Table.ColumnHeaderCell className="header-cell">
          <Text size="1">Total km</Text>
        </Table.ColumnHeaderCell>
        <Table.ColumnHeaderCell />
      </Table.Row>
    </Table.Header>
  );
};
