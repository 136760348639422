import { createFileRoute } from "@tanstack/react-router";

import { Footer } from "~/modules/Dashboard/Footer/Footer";
import { FeedbackHeader } from "~/modules/Dashboard/Header/FeedbackHeader";
import { Error } from "~/modules/Error/Error";

export const Route = createFileRoute("/error")({
  component: () => (
    <>
      <FeedbackHeader />
      <Error />
      <Footer />
    </>
  ),
});
