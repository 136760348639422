import { useState } from "react";

import * as Tooltip from "@radix-ui/react-tooltip";
import { Box } from "@radix-ui/themes";

import { Badge } from "~/models/Badge.model";
import { ExpandedBadge } from "~/modules/Dashboard/TopList/components/Badges/ExpandedBadges";

import "~/modules/Dashboard/TopList/styles/BadgeTooltip.scss";

type BadgeTooltipProps = {
  badge: Badge;
};

export const BadgeWithTooltip = ({ badge }: BadgeTooltipProps) => {
  const [open, setOpen] = useState(false);

  const handleBadgeClick = () => {
    setOpen((prev) => !prev);
  };

  return (
    <Tooltip.Provider delayDuration={300}>
      <Tooltip.Root open={open} onOpenChange={setOpen}>
        <Tooltip.Trigger asChild>
          <div onClick={handleBadgeClick}>
            <ExpandedBadge badge={badge} />
          </div>
        </Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content className="badge-tooltip-content">
            <Box
              display={{ initial: "inline-block", xs: "none" }}
              className="badge-title"
            >
              {badge.title}:&nbsp;
            </Box>
            {badge.description}
            <Tooltip.Arrow className="box-arrow" offset={0} />
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
};
