import { useMemo } from "react";

import { useQuery } from "@tanstack/react-query";

import { Athlete } from "~/models/Athlete.model";

export const useTopListData = (itemsPerPage = 10) => {
  const { data, isPending, error } = useQuery<Athlete[]>({
    queryKey: ["TopList"],
    queryFn: () => fetch("api/top-list").then((res) => res.json()),
  });

  const topListData = data ?? [];

  const topListTotalPages = useMemo(() => {
    if (!Array.isArray(topListData)) return 0;
    return Math.ceil(topListData.length / itemsPerPage);
  }, [topListData]);

  return {
    topListData,
    isTopListDataPending: isPending,
    topListDataError: error,
    topListTotalPages,
    topListHasManyPages: topListTotalPages > 1,
  };
};
