import { Flex, Text } from "@radix-ui/themes";

export const ExpandedAthleteTitle = () => {
  return (
    <Flex direction={"column"} gap={"4"}>
      <Text size={"2"} weight="bold" mb="2">
        Siste økter
      </Text>
    </Flex>
  );
};
