import { useState } from "react";

import { Grid } from "@radix-ui/themes";

import { AthleteActivity } from "~/models/AthleteActivity.model";
import { ExpandedAthleteToggleLink } from "~/modules/Dashboard/TopList/components/ExpandedAthlete/ExpandedAthleteToggleLink";

import { ExpandedAthleteRow } from "./ExpandedAthleteRow";
import { ExpandedAthleteRowTitle } from "./ExpandedAthleteRowTitle";

type ExpandedAthleteEntriesProps = {
  activities: AthleteActivity[];
};

export const ExpandedAthleteEntries = ({
  activities,
}: ExpandedAthleteEntriesProps) => {
  const [expanded, setExpanded] = useState(false);

  const handleToggleExpand = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setExpanded(!expanded);
  };

  const firstFiveActivities = activities.slice(0, 5);
  const restOfActivities = activities.slice(5);

  return (
    <Grid columns={{ initial: "1", sm: "1" }} gapX="9" gapY="2">
      <ExpandedAthleteRowTitle />
      {firstFiveActivities?.map((activity, key) => (
        <ExpandedAthleteRow key={key} activity={activity} />
      ))}

      {restOfActivities.length > 0 && (
        <>
          {expanded && (
            <>
              {restOfActivities?.map((activity, key) => (
                <ExpandedAthleteRow key={key} activity={activity} />
              ))}
              <ExpandedAthleteToggleLink
                title="Se mindre"
                toggleExpand={handleToggleExpand}
              />
            </>
          )}
        </>
      )}
    </Grid>
  );
};
