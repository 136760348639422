import type { FC } from "react";

import { Text } from "@radix-ui/themes";
import { Flex } from "@radix-ui/themes/src/index.js";

import { Section } from "~/Layout/Sections/Section";

import "./Header.scss";

export const FeedbackHeader: FC = () => {
  return (
    <Section>
      <Flex
        direction={{ initial: "column", md: "row" }}
        gapX="9"
        gapY={{ initial: "5", md: "8" }}
        mt="5"
        mb="5"
        justify="between"
        align="center"
      >
        <Flex
          direction={{ initial: "column", md: "row" }}
          gap={{ initial: "2" }}
          align={{ initial: "center", md: "end" }}
          gapY={{ initial: "4", md: "8" }}
        >
          <img
            src="/logoer/barnekreftforeningen.png"
            alt="Barnkreftforeningen Logo"
            className="barnekreftlogo"
          />
          <a href="/" className="header-link header-link--back">
            <Text
              as="p"
              weight="bold"
              size="8"
              align="left"
              className="title"
              ml={{ initial: "0", md: "2" }}
            >
              LØP FOR MEG
            </Text>
          </a>
        </Flex>
        <Flex
          direction={{ initial: "column", md: "row" }}
          gap={{ initial: "2" }}
          align={{ initial: "center", md: "end" }}
        >
          <img
            src="/logoer/computasxdagenatifi.svg"
            alt="Computas Logo og Dagen at ifi"
            className="computaslogo"
          />
        </Flex>
      </Flex>
    </Section>
  );
};
