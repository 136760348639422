import { Text } from "@radix-ui/themes";

import { ActivityBadgeNames } from "./ActivityBadgeNames";

interface ActivityInfoProps {
  athleteName: string;
  activityDistanceKM: number;
  activityBadgeNames: string[];
  sportTypeInfo: string;
}

export const ActivityInfo = ({
  athleteName,
  activityDistanceKM,
  activityBadgeNames,
  sportTypeInfo,
}: ActivityInfoProps) => {
  return (
    <div>
      <Text size="2" weight="bold">
        {athleteName}
      </Text>
      <Text size="2">
        {" "}
        {sportTypeInfo} {activityDistanceKM} km{" "}
      </Text>
      {activityBadgeNames.length > 0 && (
        <ActivityBadgeNames badgeList={activityBadgeNames} />
      )}
    </div>
  );
};
