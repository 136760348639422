import { useQuery } from "@tanstack/react-query";

import { LatestActivity } from "./LatestActivity.model";

export const useLatestActivitesData = () => {
  const { data, isPending, error } = useQuery<LatestActivity[]>({
    queryKey: ["LatestActivites"],
    queryFn: () => fetch("api/latest-activities").then((res) => res.json()),
  });

  return {
    latestActivitiesData: data ?? [],
    isLatestActivitiesPending: isPending,
    latestActivitiesError: error,
  };
};
