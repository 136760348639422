import * as Avatar from "@radix-ui/react-avatar";
import { Flex, Text } from "@radix-ui/themes";

import streak from "~/assets/badges/streak.png";
import { Badge } from "~/models/Badge.model";

import "~/modules/Dashboard/TopList/styles/BadgeComponents.scss";

type HeaderBadgesProps = {
  badges: Badge[];
  streakDays: number;
};

export const HeaderBadges = ({ badges, streakDays }: HeaderBadgesProps) => {
  return (
    <Flex ml={"0.7em"} className={"badge-component"} align={"center"} gap={"2"}>
      {streakDays > 0 && (
        <Flex>
          <Avatar.Root className={`badge-root header`}>
            <Avatar.Image
              src={streak}
              alt="Streak image"
              className="badge-image"
            />
          </Avatar.Root>
          <Text mr={"0.5em"}>{streakDays}</Text>
        </Flex>
      )}

      {badges.map((badge, key) => (
        <Flex key={key} display={{ initial: "none", sm: "flex" }}>
          <Avatar.Root className={`badge-root header`}>
            <Avatar.Image
              src={badge.image}
              alt="Badge Image"
              className="badge-image"
            />
          </Avatar.Root>
        </Flex>
      ))}
    </Flex>
  );
};
