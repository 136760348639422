import { FC } from "react";

import { Box, Flex, Text } from "@radix-ui/themes";

export const ExpandedAthleteRowTitle: FC = () => {
  return (
    <Flex width="100%" gap="1" wrap="wrap" align="center" justify="between">
      {/* Date */}
      <Box
        style={{
          flexBasis: "15%",
          maxWidth: "15%",
          overflow: "hidden",
          whiteSpace: "nowrap",
        }}
      >
        <Text size="2" weight="bold">
          Dato
        </Text>
      </Box>

      {/* Activity */}
      <Box
        style={{
          flexBasis: "45%",
          maxWidth: "45%",
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
        }}
      >
        <Text size="2" weight="bold">
          Aktivitet
        </Text>
      </Box>

      {/* Distance */}
      <Box
        display={{ initial: "none", sm: "block" }}
        style={{
          flexBasis: "25%",
          maxWidth: "25%",
          overflow: "hidden",
          whiteSpace: "nowrap",
          textAlign: "right",
        }}
      >
        <Text size="2" weight="bold" align="right">
          Distanse
        </Text>{" "}
      </Box>
      {/* Distance */}
      <Box
        display={{ initial: "block", sm: "none" }}
        style={{
          flexBasis: "25%",
          maxWidth: "25%",
          overflow: "hidden",
          whiteSpace: "nowrap",
          textAlign: "right",
        }}
      >
        <Text size="2" weight="bold" align="right">
          Distanse
        </Text>
      </Box>

      {/* Elevation Gain - visible for larger screens */}
      <Box
        display={{ initial: "none", sm: "block" }}
        style={{
          flexBasis: "10%",
          maxWidth: "10%",
          overflow: "hidden",
          whiteSpace: "nowrap",
          textAlign: "right",
        }}
      >
        <Text size="2" weight="bold">
          Høyde
        </Text>
      </Box>
    </Flex>
  );
};
