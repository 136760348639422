import { FC } from "react";

import * as Avatar from "@radix-ui/react-avatar";
import { Flex, Grid, Text } from "@radix-ui/themes";

import { Section } from "~/Layout/Sections/Section";

import { useBadges } from "./hooks/useBadges.api";

import "./Badges.scss";

export const Badges: FC = () => {
  const { badges } = useBadges();

  return (
    <Section depth={2}>
      <Flex direction={"column"} gap={"5"}>
        <Text size="7" weight="bold">
          Oppnå badges
        </Text>

        <Grid columns={{ initial: "1", sm: "2", md: "4" }} gap={"5"}>
          {badges.map((badge) => (
            <Flex key={badge.badgeId} align={"start"} gap={"3"}>
              <Avatar.Root className="AvatarRoot">
                <Avatar.Image
                  src={badge.image}
                  alt={badge.title}
                  className="AvatarImage"
                />
              </Avatar.Root>
              <Flex direction={"column"} gap={"1"}>
                <Text size="2" weight={"bold"}>{badge.title}</Text>
                <Text size={"2"}>{badge.description}</Text>
              </Flex>
            </Flex>
          ))}
        </Grid>
      </Flex>
    </Section>
  );
};
