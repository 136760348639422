import {
  ChevronLeftIcon,
  ChevronRightIcon,
  DoubleArrowLeftIcon,
  DoubleArrowRightIcon,
} from "@radix-ui/react-icons";
import { Button, Flex, Text } from "@radix-ui/themes";
import classNames from "classnames";

import "./Pagination.scss";

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
  isDisabled: boolean;
  clearExpand: () => void;
}

const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  totalPages,
  onPageChange,
  isDisabled,
  clearExpand,
}) => {
  return (
    <Flex
      direction="column"
      align="center"
      mt="5"
      className="pagination-container"
    >
      <Flex align="center" gap="2">
        <Button
          variant="surface"
          disabled={isDisabled || currentPage === 1}
          onClick={() => {
            onPageChange(1);
            clearExpand();
          }}
          className={classNames("pagination-button", {
            clickable: !isDisabled && currentPage !== 1,
          })}
          aria-label="Go to first page"
        >
          <DoubleArrowLeftIcon />
        </Button>

        <Button
          variant="surface"
          disabled={isDisabled || currentPage === 1}
          onClick={() => {
            onPageChange(currentPage - 1);
            clearExpand();
          }}
          className={classNames("pagination-button", {
            clickable: !isDisabled && currentPage !== 1,
          })}
        >
          <ChevronLeftIcon />
        </Button>

        <Text>
          {currentPage} av {totalPages}
        </Text>

        <Button
          variant="surface"
          disabled={isDisabled || currentPage === totalPages}
          onClick={() => {
            onPageChange(currentPage + 1);
            clearExpand();
          }}
          className={classNames("pagination-button", {
            clickable: !isDisabled && currentPage !== totalPages,
          })}
        >
          <ChevronRightIcon />
        </Button>

        <Button
          variant="surface"
          disabled={isDisabled || currentPage === totalPages}
          onClick={() => {
            onPageChange(totalPages);
            clearExpand();
          }}
          className={classNames("pagination-button", {
            clickable: !isDisabled && currentPage !== totalPages,
          })}
          aria-label="Go to last page"
        >
          <DoubleArrowRightIcon />
        </Button>
      </Flex>
    </Flex>
  );
};

export default Pagination;
