import { Flex, Text } from "@radix-ui/themes";

type ContributionProps = {
  totalSum: number;
};

export const ExpandedMobileContribution = ({ totalSum }: ContributionProps) => {
  return (
    <Flex direction={"row"}>
      <Text size={"2"} weight={"bold"}>
        Totalt innsamlet: &nbsp;
      </Text>
      <Text size={"2"}> {totalSum} kr</Text>
    </Flex>
  );
};
