import { useState } from "react";

import { Cross2Icon, MagnifyingGlassIcon } from "@radix-ui/react-icons";
import * as Tabs from "@radix-ui/react-tabs";
import { Box, Button, Flex, Text, TextField } from "@radix-ui/themes";

import { Section } from "~/Layout/Sections/Section";
import { SearchComponent } from "~/modules/Dashboard/SearchComponent/SearchComponent";

import "./TabTopListActivities.scss";

export const TabTopListActivities = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [activeTab, setActiveTab] = useState<"toplist" | "latestActivities">(
    "toplist",
  );

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    if (newValue.length === 0 || newValue.trim() !== "") {
      setSearchTerm(newValue);
      setActiveTab("toplist");
    }
  };

  const handleTabChange = (value: string) => {
    setActiveTab(value as "toplist" | "latestActivities");
    if (value === "latestActivities") {
      setSearchTerm("");
    }
  };

  const handleSearchQuit = () => {
    setSearchTerm("");
  };

  return (
    <Section secondary depth={2}>
      <Tabs.Root
        className="tab-component"
        value={activeTab}
        onValueChange={handleTabChange}
      >
        <Flex direction="column" gap="3" mb="4">
          <Flex
            direction={{ initial: "column", sm: "row" }}
            justify={{ sm: "between" }}
            align={{ initial: "start", sm: "center" }}
            gap="3"
            width="100%"
          >
            <Tabs.List aria-label="View top list or activities">
              <Flex direction="row" gap="2">
                <Tabs.Trigger className="trigger" value="toplist">
                  <Text>Toppliste</Text>
                </Tabs.Trigger>
                <Tabs.Trigger className="trigger" value="latestActivities">
                  <Text>Siste økter</Text>
                </Tabs.Trigger>
              </Flex>
            </Tabs.List>

            <Box width={{ initial: "100%", sm: "12em" }}>
              <TextField.Root
                className="search-input"
                value={searchTerm}
                onChange={handleSearchChange}
                placeholder="Søk etter navn..."
                size="2"
              >
                <TextField.Slot>
                  <MagnifyingGlassIcon height="16" width="16" />
                </TextField.Slot>
                {searchTerm !== "" && (
                  <TextField.Slot>
                    <Button
                      size="1"
                      onClick={handleSearchQuit}
                      variant="soft"
                      className="cross-button "
                    >
                      <Cross2Icon height="16" width="16" />
                    </Button>
                  </TextField.Slot>
                )}
              </TextField.Root>
            </Box>
          </Flex>
        </Flex>

        <SearchComponent searchTerm={searchTerm} activeTab={activeTab} />
      </Tabs.Root>
    </Section>
  );
};
