import { FC } from "react";

import { ChevronDownIcon } from "@radix-ui/react-icons";
import { Flex, IconButton, Table, Text } from "@radix-ui/themes";
import classNames from "classnames";

import { Athlete } from "~/models/Athlete.model";
import AvatarComponent from "~/modules/Dashboard/TopList/components/AvatarComponent";
import { HeaderBadges } from "~/modules/Dashboard/TopList/components/Badges/HeaderBadges";

type ToplistRowProps = {
  athlete: Athlete;
  rowClassName: "odd" | "even";
  rowNumber: number;
  isExpanded: boolean;
  toggleExpand: (key: number) => void;
};

export const DesktopTopListRow: FC<ToplistRowProps> = ({
  athlete,
  rowClassName,
  rowNumber,
  isExpanded,
  toggleExpand,
}) => {
  return (
    <Table.Row
      className={`table-row ${rowClassName}`}
      onClick={() => toggleExpand(rowNumber)}
    >
      <Table.Cell>
        <Flex align="center" gap="2">
          <Text
            className="rank-cell"
            size={{ initial: "1", sm: "2" }}
            align="right"
          >
            {athlete.rank}.
          </Text>
          <AvatarComponent profilePicture={athlete.profilePicture} />
          <Text size="2">{athlete.name}</Text>
          <HeaderBadges
            badges={athlete.badges}
            streakDays={athlete.streakDays}
          />
        </Flex>
      </Table.Cell>

      {/* Contribution visible in desktop only */}
      <Table.Cell className="row-cell">
        <Text size="2">
          {athlete.individualContribution.toLocaleString("no-nb")} kr
        </Text>
      </Table.Cell>

      {/* Distance column always visible */}
      <Table.Cell className="row-cell">
        <Text size="2">{athlete.totalDistanceKM} km</Text>
      </Table.Cell>

      <Table.Cell className="end-cell">
        <IconButton
          className={classNames("expand-button", {
            "is-expanded": isExpanded,
          })}
          variant="soft"
        >
          <ChevronDownIcon />
        </IconButton>
      </Table.Cell>
    </Table.Row>
  );
};
