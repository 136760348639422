import { FC, useEffect, useRef, useState } from "react";

import { Box, Text } from "@radix-ui/themes";

import { Athlete } from "~/models/Athlete.model";

import Pagination from "./components/Pagination/Pagination";
import { DesktopTopList } from "./components/TopList/DesktopTopList";
import { MobileTopList } from "./components/TopList/MobileTopList";

import "./styles/TopList.scss";

interface TopListProps {
  filteredData: Athlete[];
  currentPage: number;
  onPageChange: (page: number) => void;
  ITEMS_PER_PAGE: number;
  totalPages: number;
  hasResults: boolean;
}

export const TopList: FC<TopListProps> = ({
  filteredData,
  currentPage,
  onPageChange,
  ITEMS_PER_PAGE,
  totalPages,
  hasResults,
}) => {
  const [expandedUsers, setExpandUsers] = useState<Set<number>>(new Set());
  const listRef = useRef<HTMLDivElement | null>(null);
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const toggleExpand = (id: number) => {
    const newExpandedUsers = new Set(expandedUsers);
    expandedUsers.has(id)
      ? newExpandedUsers.delete(id)
      : newExpandedUsers.add(id);
    setExpandUsers(newExpandedUsers);
  };

  const clearToggleExpand = () => {
    setExpandUsers(new Set());
  };

  useEffect(() => {
    if (!isFirstLoad && listRef.current) {
      listRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    } else {
      setIsFirstLoad(false);
    }
  }, [currentPage]);

  return (
    <div ref={listRef}>
      {hasResults ? (
        <>
          <Box display={{ initial: "none", sm: "block" }}>
            <DesktopTopList
              paginatedData={filteredData}
              expandedUsers={expandedUsers}
              toggleExpand={toggleExpand}
              currentPage={currentPage}
              ITEMS_PER_PAGE={ITEMS_PER_PAGE}
            />
          </Box>

          <Box display={{ initial: "block", sm: "none" }}>
            <MobileTopList
              paginatedData={filteredData}
              expandedUsers={expandedUsers}
              toggleExpand={toggleExpand}
              currentPage={currentPage}
              ITEMS_PER_PAGE={ITEMS_PER_PAGE}
            />
          </Box>

          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={onPageChange}
            isDisabled={!hasResults}
            clearExpand={clearToggleExpand}
          />
        </>
      ) : (
        <Text>No results found</Text>
      )}
    </div>
  );
};
