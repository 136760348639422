import { Flex } from "@radix-ui/themes";

import { Badge } from "~/models/Badge.model";
import { BadgeWithTooltip } from "~/modules/Dashboard/TopList/components/Badges/BadgeWithTooltip";
import { ExpandedBadge } from "~/modules/Dashboard/TopList/components/Badges/ExpandedBadges";

type BadgesOverviewProps = {
  badges: Badge[];
};

export const BadgesOverview = ({ badges }: BadgesOverviewProps) => {
  return (
    <Flex gapX={"5"} gapY={"3"} wrap={"wrap"}>
      {badges?.map((badge, key) => (
        <BadgeWithTooltip key={key} badge={badge} />
      ))}
    </Flex>
  );
};
