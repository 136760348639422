import { FC, Fragment } from "react";

import { Table } from "@radix-ui/themes";

import { Athlete } from "~/models/Athlete.model";
import { ExpandedAthlete } from "~/modules/Dashboard/TopList/components/ExpandedAthlete";

import { MobileTopListHeader } from "./MobileTopListHeader";
import { MobileTopListRow } from "./MobileTopListRow";

type MobileTopListProps = {
  paginatedData: Athlete[];
  expandedUsers: Set<number>;
  toggleExpand: (key: number) => void;
  currentPage: number;
  ITEMS_PER_PAGE: number;
};

export const MobileTopList: FC<MobileTopListProps> = ({
  paginatedData,
  expandedUsers,
  toggleExpand,
  currentPage,
  ITEMS_PER_PAGE,
}) => {
  return (
    <Table.Root variant={"ghost"} className="toplist">
      <MobileTopListHeader />
      <Table.Body>
        {paginatedData.map((athlete, key) => {
          const isExpanded = expandedUsers.has(athlete.id);
          const rowClassName = key % 2 === 0 ? "odd" : "even";

          return (
            <Fragment key={key}>
              <MobileTopListRow
                athlete={athlete}
                rowClassName={rowClassName}
                rowNumber={(currentPage - 1) * ITEMS_PER_PAGE + key + 1}
                isExpanded={isExpanded}
                toggleExpand={() => toggleExpand(athlete.id)}
              />

              {isExpanded && (
                <Table.Row className={`table-row ${rowClassName}`}>
                  <Table.Cell colSpan={4}>
                    <ExpandedAthlete
                      activities={athlete.activities}
                      badges={athlete.badges}
                      individualContribution={athlete.individualContribution}
                    />
                  </Table.Cell>
                </Table.Row>
              )}
            </Fragment>
          );
        })}
      </Table.Body>
    </Table.Root>
  );
};
