import { FC, useEffect, useMemo, useState } from "react";

import { Box, Text } from "@radix-ui/themes";

import { Athlete } from "~/models/Athlete.model";
import { LatestActivities } from "~/modules/Dashboard/LatestActivities/LatestActivities";
import { useLatestActivitesData } from "~/modules/Dashboard/LatestActivities/useLastestActivites.api";
import { TopList } from "~/modules/Dashboard/TopList/TopList";
import { useTopListData } from "~/modules/Dashboard/TopList/useTopListData.api";

interface CombinedSearchComponentProps {
  searchTerm: string;
  activeTab: "toplist" | "latestActivities";
}

const ITEMS_PER_PAGE = 15;

export const SearchComponent: FC<CombinedSearchComponentProps> = ({
  searchTerm,
  activeTab,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const { topListData, isTopListDataPending } = useTopListData();
  const { latestActivitiesData, isLatestActivitiesPending } =
    useLatestActivitesData();

  const filteredTopListData = useMemo(() => {
    if (!Array.isArray(topListData)) return [];
    return topListData.filter((athlete: Athlete) =>
      athlete.name.toLowerCase().includes(searchTerm.toLowerCase()),
    );
  }, [topListData, searchTerm]);

  const totalPages = Math.max(
    1,
    Math.ceil(filteredTopListData.length / ITEMS_PER_PAGE),
  );

  const paginatedData = useMemo(() => {
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    return filteredTopListData.slice(startIndex, endIndex);
  }, [filteredTopListData, currentPage]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [searchTerm]);

  const hasResults = filteredTopListData.length > 0;

  return (
    <Box>
      {isTopListDataPending || isLatestActivitiesPending ? (
        <Text>Laster...</Text>
      ) : activeTab === "toplist" ? (
        <TopList
          filteredData={paginatedData}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          ITEMS_PER_PAGE={ITEMS_PER_PAGE}
          totalPages={totalPages}
          hasResults={hasResults}
        />
      ) : (
        <LatestActivities data={latestActivitiesData} />
      )}
    </Box>
  );
};
