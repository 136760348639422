import type { FC } from "react";

import { Button, Grid, Text } from "@radix-ui/themes";
import { Flex } from "@radix-ui/themes/src/index.js";
import classNames from "classnames";

import { Section } from "~/Layout/Sections/Section";

import "./Header.scss";

export const Header: FC = () => {
  const registeredCookie = document.cookie.split("; registered=")[1] ?? false;

  const isRegistered = !!(
    registeredCookie && registeredCookie.startsWith("true")
  );

  return (
    <Section>
      <Grid
        columns={{ xs: "1", md: "auto auto" }}
        gapX="9"
        gapY={{ initial: "5", md: "8" }}
        rows={"auto auto"}
        mt="5"
        mb="5"
        align="center"
      >
        <Flex
          direction={{ initial: "column", md: "row" }}
          gap={{ initial: "2" }}
          align={{ initial: "center", md: "end" }}
          gapY={{ initial: "4", md: "8" }}
        >
          <img
            src="/logoer/barnekreftforeningen.png"
            alt="Computas Logo og Dagen at ifi"
            className="barnekreftlogo"
          />
          <a href="/" className="header-link header-link--back">
            <Text
              as="p"
              weight="bold"
              size="8"
              align="left"
              className="title"
              ml={{ initial: "0", md: "2" }}
            >
              LØP FOR MEG
            </Text>
          </a>
        </Flex>
        <Flex
          direction={{ initial: "column", md: "row" }}
          gap={{ initial: "2" }}
          align={{ initial: "center", md: "end" }}
        >
          <img
            src="/logoer/computasxdagenatifi.svg"
            alt="Computas Logo og Dagen at ifi"
            className="computaslogo"
          />
        </Flex>

        <Text as="p" size="4" className="full-width-text">
          Vinn premier og løp for en god sak! Bli med å samle inn penger til
          TV-aksjonen som går til barnekreftforeningen! Ved å koble til Strava
          vil dine økter bli registrert og du vil være med å samle penger.
        </Text>

        <Flex
          direction="column"
          gapX="7"
          gapY="7"
          align={{ initial: "center", md: "end" }}
        >
          <Button
            size="4"
            disabled={isRegistered}
            onClick={() =>
              (window.location.href = "/api/redirect-to-strava-auth")
            }
            className={classNames(
              { "header-button": !isRegistered },
              { "header-button_disabled": isRegistered },
            )}
          >
            {isRegistered ? "Du er påmeldt!" : "Bli med!"}
          </Button>
        </Flex>
      </Grid>
    </Section>
  );
};
