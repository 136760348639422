import { createFileRoute } from "@tanstack/react-router";

import { Footer } from "~/modules/Dashboard/Footer/Footer";
import { Header } from "~/modules/Dashboard/Header/Header";
import { GDPR } from "~/modules/GDPR/GDPR";

export const Route = createFileRoute("/gdpr")({
  component: () => (
    <>
      <Header />
      <GDPR />
      <Footer />
    </>
  ),
});
